import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { dateString, subscriptionStatusMap } from "../utils/formatters";
import { useBackendApi } from "../contexts/BackendApiContext";
import { Loader } from "./Loader";
import { CopyIcon, EyeNoneIcon, EyeOpenIcon } from "@radix-ui/react-icons";
import { Column, Row } from "./PageLayout";
import { Tooltip } from "./Tooltip";
import { useAuth } from "../contexts/AuthContext";

const ListElement = ({ name, value }: any) => <div><span className="font-bold">{name}: </span><span>{value}</span></div>

const API_KEY_HIDDEN = '********';

export function Subscriber() {
    const { api } = useBackendApi();
    const { getTokens } = useAuth();
    const [subscription, setSubscription] = useState<any>(undefined);
    const navigate = useNavigate();
    const [showApiKey, setShowApiKey] = useState<boolean>(false);
    const [processingActivation, setProcessingActivation] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);

    useEffect(() => {
        getTokens().then(({ marketplaceToken, accessToken }) => {
            api.getSubscriptionData(marketplaceToken!, accessToken)
                .then(async (response) => {
                    if (response.ok) {
                        const responseData = await response.json();
                        setSubscription(responseData.subscription);
                    }
                })
                .catch(error => {
                    console.error('Error getting subscription data:', error);
                    setShowError(true);
                });
        }).catch(error => {
            console.error('Error getting tokens:', error);
            navigate('./unauthorized');
        });
    }, [api, getTokens, navigate]);

    useEffect(() => {
        if (processingActivation) {
            getTokens().then(({ marketplaceToken, accessToken }) => {
                api.requestSubscriptionActivation(marketplaceToken!, accessToken)
                    .then(async (response) => {
                        if (response.ok) {
                            const responseData = await response.json();
                            setSubscription(responseData.subscription);
                        }
                    })
                    .catch(error => {
                        console.error('Error requesting activation:', error);
                        setShowError(true);
                    });
            }).catch(error => {
                console.error('Error getting tokens:', error);
                navigate('./unauthorized');
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processingActivation]);

    const toggleApiKey = useCallback(async () => {
        if (!subscription.apiKey) {
            return;
        }
        setShowApiKey(value => !value);
    }, [subscription?.apiKey]);

    const copyApiKey = useCallback(async () => {
        if (!subscription.apiKey) {
            return;
        }
        navigator.clipboard.writeText(subscription.apiKey);
    }, [subscription?.apiKey]);

    const copyApiUrl = useCallback(() => {
        if (!subscription.apiUrl) {
            return;
        }
        navigator.clipboard.writeText(subscription.apiUrl);
    }, [subscription?.apiUrl]);

    /* render */

    if (!subscription) {
        return <Loader className="mt-20" />;
    }

    return (
        <>
            <div className="flex flex-col gap-8 text-sm">
                <div className="flex items-stretch gap-2 flex-nowrap w-full px-2">
                    <div className="text-2xl bg-white p-1">Azure Subscription Information</div>
                    <div className="text-2xl font-light bg-white p-1 border-l-2 pl-3">{subscription.name}</div>
                </div>
                {
                    showError === true &&
                    <div className="rounded-lg px-4 py-2 -my-4 bg-red-100 border-l-8 border-l-red-500">
                        There was an issue with your request, please try again later or contact support for more details.
                    </div>
                }
                {
                    subscription.status === 'Subscribed' &&
                    <div className="rounded-lg px-4 py-2 -my-4 bg-green-100 border-l-8 border-l-green-500">
                        The subscription is currently active. To cancel it, go to the resource in the Azure Portal.
                    </div>
                }
                {
                    subscription.status === 'PendingFulfillmentStart' && subscription.activationRequested === true &&
                    <div className="rounded-lg px-4 py-2 -my-4 bg-green-100 border-l-8 border-l-green-500">
                        We are provisioning the resources for your subscription. It might take 1-3 days to set this up.
                    </div>
                }
                {
                    processingActivation && subscription.activationRequested !== true &&
                    <Loader />
                }
                <Row>
                    <Column first>
                        <ListElement name='id' value={subscription.id} />
                        <ListElement name='plan' value={subscription.planId} />
                        <ListElement name='tenant id' value={subscription.tenantId} />
                    </Column>
                    <Column>
                        <ListElement name='created' value={dateString(subscription.created)} />
                        <ListElement name='period' value={subscription.period} />
                    </Column>
                    <Column>
                        <ListElement name='status' value={subscriptionStatusMap(subscription.status)} />
                        {
                        !!subscription.activationDate && subscription.status !== 'PendingFulfillmentStart' &&
                        <ListElement name='activation date' value={dateString(subscription.activationDate)} />
                    }
                        <div className="w-fit">
                            {
                                subscription.status === 'PendingFulfillmentStart' && subscription.activationRequested === false &&
                                <button
                                    onClick={() => { setProcessingActivation(true); }}
                                    hidden={processingActivation}
                                    className="rounded px-2 py-1 bg-blue-400 hover:bg-blue-500 active:bg-blue-600 text-white mt-2"
                                >Activate Subscription</button>
                            }
                        </div>
                    </Column>
                </Row>
                {
                    subscription?.status === 'Subscribed' && (
                        <Row>
                            {
                                !!subscription.projectIds && subscription?.projectIds.length > 0 && subscription?.apiKey
                                    ? <>
                                        <Column first>
                                            <ListElement name='projects' value='' />
                                            {
                                                subscription.projectIds?.map((project: any) => <div key={project}><span>{project}</span></div>)
                                            }
                                        </Column>
                                        <Column>
                                            <ListElement
                                                name='API key'
                                                value={
                                                    <>
                                                        <Tooltip message={showApiKey ? 'hide' : 'show'}>
                                                            <button
                                                                onClick={toggleApiKey}
                                                                className="text-blue-500 hover:underline ml-1"
                                                            >{showApiKey ? <EyeNoneIcon /> : <EyeOpenIcon />}</button>
                                                        </Tooltip>
                                                        <Tooltip message="copy">
                                                            <button
                                                                onClick={copyApiKey}
                                                                className="text-blue-500 hover:underline ml-1"
                                                            ><CopyIcon /></button>
                                                        </Tooltip>
                                                    </>
                                                }
                                            />
                                            <div><span>{showApiKey ? subscription?.apiKey : API_KEY_HIDDEN}</span></div>
                                            <ListElement
                                                name='API URL'
                                                value={
                                                    <>
                                                        <span>{subscription.apiUrl}</span>
                                                        <Tooltip message="copy">
                                                            <button
                                                                onClick={copyApiUrl}
                                                                className="text-blue-500 hover:underline ml-1"
                                                            ><CopyIcon /></button>
                                                        </Tooltip>
                                                    </>
                                                }
                                            />
                                            <ListElement
                                                name='API docs'
                                                value={
                                                    <a
                                                    href={`${subscription.apiUrl}/docs`}
                                                    target="_blank"
                                                    rel="noreferrer noopener"
                                                    className="text-blue-500 hover:underline active:text-blue-600">open documentation</a>
                                                }
                                            />
                                        </Column>
                                    </>
                                    : <Loader className="mt-8 w-full" />
                            }
                        </Row>
                    )
                }
            </div>
        </>
    );
}
